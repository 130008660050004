import React from "react";

export default function UnderConstruction() {
  return (
    <div>
      <img
        style={{
          display: "block",
          marginLeft: "auto",
          marginRight: "auto",
          width: "50%",
        }}
        src="../../assets/img/construccion.jpg"
      />
    </div>
  );
}
