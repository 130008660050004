import Navbar from "react-bootstrap/Navbar";
import Container from "react-bootstrap/Container";

export default function Header() {
  return (
    <Navbar sticky="top">
      <Container fluid>
        <Navbar.Brand>Nolo COBRALINK</Navbar.Brand>
      </Container>
    </Navbar>
  );
}
