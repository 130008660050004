import Footer from "./components/Footer";
import Header from "./components/Header";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import UnderConstruction from "./components/UnderConstruction";

function App() {
  return (
    <>
      <Header />
      <Router>
        <Routes>
          <Route path="*" element={<UnderConstruction />} />
        </Routes>
      </Router>
      <Footer />
    </>
  );
}

export default App;
