import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";

export default function Footer() {
	return (
		<Container fluid>
			<footer className="d-flex flex-wrap justify-content-between align-items-center py-3 my-4 border-top fs-4">
				<Col md={4} className="d-flex align-items-center">
					<a
						href="/"
						className="mb-3 me-2 mb-md-0 text-muted text-decoration-none lh-1"
					>
						<span className="text-muted">
							&copy; 2021 - {new Date().getFullYear()} Nolo
							COBRALINK
						</span>
					</a>
				</Col>

				<ul className="nav col-md-4 justify-content-end list-unstyled d-flex">
					<li className="ms-3">
						<a
							className="text-muted"
							target="_blank"
							rel="noreferrer"
							href="https://www.facebook.com/nolo.cobralink"
						>
							<i className="bi bi-facebook"></i>
						</a>
					</li>
					<li className="ms-3">
						<a
							className="text-muted"
							target="_blank"
							rel="noreferrer"
							href="https://twitter.com/NoloCOBRALINK"
						>
							<i className="bi bi-twitter"></i>
						</a>
					</li>
					<li className="ms-3">
						<a
							className="text-muted"
							target="_blank"
							rel="noreferrer"
							href="https://www.instagram.com/nolocobralink"
						>
							<i className="bi bi-instagram"></i>
						</a>
					</li>
					<li className="ms-3">
						<a
							className="text-muted"
							target="_blank"
							rel="noreferrer"
							href="https://www.twitch.tv/NoloCOBRALINK"
						>
							<i className="bi bi-twitch"></i>
						</a>
					</li>
					<li className="ms-3">
						<a
							className="text-muted"
							target="_blank"
							rel="noreferrer"
							href="https://www.github.com/nolocobralink"
						>
							<i className="bi bi-github"></i>
						</a>
					</li>
				</ul>
			</footer>
		</Container>
	);
}
